import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {gestor} from '../servicos/gestor';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export function configAnalytics(tracking_id: string): Boolean {
    try {
        ReactGA.initialize(tracking_id, {
            gaOptions: {cookieFlags: 'SameSite=None; Secure'},
        });
        return true;
    } catch (e: any) {
        console.info("Erro ao configurar o analytics: ", e);
        return false;
    }
}

export const TrackingRoute = () => {
    const location = useLocation();
    const [trackingId, setTrackingId] = useState<string | undefined>();

    useEffect(() => {
        gestor.getInformacoes().then(response => {
            if (!response.ga_tracking_id) return;

            const ok = configAnalytics(response.ga_tracking_id);
            if (ok) setTrackingId(response.ga_tracking_id);

            // Configuração do GTM
            const tagManagerArgs = {
                gtmId: 'GTM-W4VH9C2C', // Substitua pelo seu ID do GTM
                dataLayer: {
                    event: 'pageview',
                    page: window.location.pathname + window.location.search,
                },
            };
            TagManager.dataLayer(tagManagerArgs);

            // console.info("Google Analytics Configurado: ", ok);
        });
    }, []);

    useEffect(() => {
        if (trackingId) {
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname + window.location.search,
            });

            // Envio de evento para o GTM
            TagManager.dataLayer({
                dataLayer: {
                    event: 'pageview',
                    page: window.location.pathname + window.location.search,
                },
            });
        }
    }, [location, trackingId]);

    return (<></>);
};
